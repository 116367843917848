/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { ethers } from "ethers";
import { createContext, useContext, useEffect, useState } from 'react';
import { ensUri, marketUri } from '../constants/base.constant';

const PAGE_SIZE = 1000;
const headers = {
  "content-type": "application/json"
};

export const MetadataContext = createContext({
  aceDomains: [],
});

/**
 * @returns 
 * key: labelName
 * values:
 *    tokenId: string
 *    labelName: string
 *    image: string
 *    category: string
 *    nameLength: number
 *    registrationDate: Date
 *    price: number
 */
export function MetadataProvider({ children }) {
  const [aceDomains, setAceDomains] = useState([]);

  const buildAceDomainsData = async () => {
    let allAuctionedNames = [];
    let allDomains = [];
    let allRegistrations = [];
    let hasMore = true;

    do {
      const { data: { data: { auctionedNames } } } = await axios({
        url: ensUri,
        method: "POST",
        headers,
        data: {
          "operationName": "fetchAllAuctionedNames",
          "query": `query fetchAllAuctionedNames {
            auctionedNames(
              first: ${PAGE_SIZE}
              skip: ${allAuctionedNames.length}
              where: { state: "AUCTION" }
            ) {
              tokenID
              price
            }
          }`
        }
      });

      hasMore = auctionedNames.length > 0;
      if (!hasMore) break;

      allAuctionedNames = allAuctionedNames.concat(auctionedNames);

      const tokenIds = auctionedNames.map((auctionedName) => auctionedName.tokenID);

      const { data: { data: { domains } } } = await axios({
        url: marketUri,
        method: "POST",
        headers,
        data: {
          "operationName": "fetchAllDomainNames",
          "query": `query fetchAllDomainNames {
          domains(
            first: ${PAGE_SIZE}
            where: {
              id_in: ["${tokenIds.join('","')}"]
              labelName_not: null
            }
          ) {
            id
            labelName
            premium
          }
        }`
        }
      });

      allDomains = allDomains.concat(domains);
      const labelNames = allDomains.map((domain) => domain.labelName);

      const { data: { data: { nameRegistereds } } } = await axios({
        url: marketUri,
        method: "POST",
        headers,
        data: {
          "operationName": "fetchAllRegistrationDates",
          "query": `query fetchAllRegistrationDates {
            nameRegistereds(
              first: ${PAGE_SIZE}
              where: {
                registration_: {
                  labelName_in: ["${labelNames.join('","')}"]
                }
              }
            ) {
              registration {
                labelName
                registrationDate
              }
            }
          }`
        }
      });

      allRegistrations = allRegistrations.concat(nameRegistereds);

    } while (hasMore)

    const aceDomains_ = [];
    await Promise.all(allAuctionedNames.map(async (auctionedName) => {
      const labelName = allDomains.filter((domain) => domain.id == auctionedName.tokenID).at(0)?.labelName;
      const premium = allDomains.filter((domain) => domain.id == auctionedName.tokenID).at(0)?.premium;
      const date =  allRegistrations.filter((domain) => domain.registration.labelName == labelName).at(0)?.registration?.registrationDate;
      if (labelName) {
        const labelHash = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(labelName));
        const hash = ethers.BigNumber.from(labelHash).toString();
        var category;
        switch (labelName.length) {
          case 2:
            category = "Gold";
            break;
          case 3:
            category = "Silver";
            break;
          case 4:
            category = "Copper";
            break;
          case 5:
          case 6:
          case 7:
            category = "Carbon";
            break;
          case 8:
            category = "Obsidian";
            break;

          default:
            category = "Obsidian";
            break;
        }
        aceDomains_.push({
          tokenId: auctionedName.tokenID,
          labelName,
          image: `${process.env.REACT_APP_METADATA_SERVICE}/endurance/${process.env.REACT_APP_DOMAIN_CONTRACT}/${hash}/image`,
          category: category,
          nameLength: labelName.length,
          registrationDate: new Date(date * 1000),
          supreme: premium,
          price: Math.round(+auctionedName.price / 10 ** 18)
        });
      }
    }));
    setAceDomains(aceDomains_);
  }

  useEffect(async () => {
    buildAceDomainsData();
  }, []);

  return <MetadataContext.Provider value={{ aceDomains }}>{children}</MetadataContext.Provider>;
}

export const useMetadataContext = () => useContext(MetadataContext);