import React from "react";
import cn from "classnames";
import styles from "./Checkout.module.sass";
import Icon from "../../../../components/Icon";
import LoaderCircle from "../../../../components/LoaderCircle";

const items = [
  {
    title: "0.007",
    value: "ETH",
  },
  {
    title: "Your balance",
    value: "8.498 ETH",
  },
  {
    title: "Service fee",
    value: "0 ETH",
  },
  {
    title: "You will pay",
    value: "0.007 ETH",
  },
];

const Checkout = ({ className, handlePurchase, itemPrice }) => {

  const converPrice = (labelprice) =>{
    let price = parseInt(labelprice);
    return (price/10**18);
  }

  return (
    <div className={cn(className, styles.checkout)}>
      <div className={cn("h4", styles.title)}>Purchase Now</div>
      {/* <div className={styles.info}>
        You are about to purchase <strong>C O I N Z</strong> from{" "}
        <strong>UI8</strong>
      </div> */}
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>{"You will pay"}</div>
          <div className={styles.col}>{itemPrice} ACE</div>
        </div>
      </div>
      {/* <div className={styles.attention}>
        <div className={styles.preview}>
          <Icon name="info-circle" size="32" />
        </div>
        <div className={styles.details}>
          <div className={styles.subtitle}>This creator is not verified</div>
          <div className={styles.text}>Purchase this item at your own risk</div>
        </div>
      </div>
      <div className={cn("h4", styles.title)}>Follow steps</div> */}
      {/* <div className={styles.line}>
        <div className={styles.icon}>
          <LoaderCircle className={styles.loader} />
        </div>
        <div className={styles.details}>
          <div className={styles.subtitle}>Purchasing</div>
          <div className={styles.text}>
            Sending transaction with your wallet
          </div>
        </div>
      </div> */}
      <div className={styles.btns}>
        <button className={cn("button", styles.button)} onClick={handlePurchase}>
          Purchase
        </button>
        {/* <button className={cn("button-stroke", styles.button)}>Cancel</button> */}
      </div>
    </div>
  );
};

export default Checkout;
