import { InjectedConnector } from "@web3-react/injected-connector";
import { ethers } from "ethers";

const RPC_URLS = {
  648: "https://rpc-endurance.fusionist.io",
};
const POLLING_INTERVAL = 12000;
const rpcUrl = RPC_URLS[0]; //getNodeUrl();
const chainId = parseInt("648", 10);

export const injected = new InjectedConnector({ supportedChainIds: [648] });

export const getLibrary = (provider) => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = POLLING_INTERVAL;
  return library;
};

const enduranceNetwork = {
  name: "endurance",
  chainId: 648,
  ensAddress: "0x152764920dfE3B8152779863c5eF407CF7b61a92",
};

export const fusionistProvider = new ethers.providers.JsonRpcProvider(
  "https://rpc-endurance.fusionist.io",
  enduranceNetwork
);
