import { Web3ReactProvider } from "@web3-react/core";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ensClient } from './clients/clients';
import { MetadataProvider } from './context/MetadataContext';
import { WalletModalProvider } from './context/WalletModalContext';
import { getLibrary } from './utils/connector';
import { ApolloProvider } from "@apollo/client";

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <WalletModalProvider>
        <ApolloProvider client={ensClient}>
          <MetadataProvider>
            <App />
          </MetadataProvider>
        </ApolloProvider>
      </WalletModalProvider>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
