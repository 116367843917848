/* eslint-disable import/prefer-default-export */
import AuctionRegistrarAbi from '../contracts/Nft_Marketplace.json';
import FTMBaseRegistrarAbi from '../contracts/BaseRegistrarImplementation.json';
import { ethers } from "ethers";
import * as dotenv from 'dotenv'
dotenv.config()



export const AuctionRegistrarAddress = process.env.REACT_APP_MARKETPLACE_CONTRACT;

export const FTMBaseRegistrarAddress = process.env.REACT_APP_DOMAIN_CONTRACT;

export const getContractHash = (connector) => {
  if (!connector) return null;
  const walletProvider = connector;
  let nftmarketContract = new ethers.Contract(
    AuctionRegistrarAddress,
    AuctionRegistrarAbi,
    walletProvider
  );
  return nftmarketContract;
};

export const getContractFTM = (connector) => {
  if (!connector) return null;
  const walletProvider = connector;
  let nftContract = new ethers.Contract(
    FTMBaseRegistrarAddress,
    FTMBaseRegistrarAbi,
    walletProvider
  );
  return nftContract;
};
