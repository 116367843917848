import React from "react";
import cn from "classnames";
import styles from "./Accept.module.sass";


const Accept = ({ className, handleAccept, maxbidprice, maxBidder }) => {
  return (
    <div className={cn(className, styles.accept)}>
      <div className={styles.line}>
        <div className={styles.icon}></div>
        <div className={styles.text}>
          You are about to accept a bid for <strong>{maxbidprice} ACE</strong> from{" "}
          <strong>{maxBidder}</strong>
        </div>
      </div>
      <div className={styles.btns}>
        <button className={cn("button", styles.button)} onClick={handleAccept}>
          Accept bid
        </button>
      </div>
    </div>
  );
};

export default Accept;
