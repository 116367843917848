import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import cn from "classnames";
import styles from "./Control.module.sass";
import Checkout from "./Checkout";
import Connect from "../../../components/Connect";
import Bid from "../../../components/Bid";
import { AwesomeButton } from "react-awesome-button";
import Accept from "./Accept";
import PutSale from "./PutSale";
import SuccessfullyPurchased from "./SuccessfullyPurchased";
import Modal from "../../../components/Modal";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import { useWallet } from "../../../hooks/useWallet";
import {
  getContractHash,
  getContractFTM,
  FTMBaseRegistrarAddress,
  AuctionRegistrarAddress,
} from "../../../utils/getContract";

const sha3 = require("js-sha3").keccak_256;

const Control = ({
  className,
  owner,
  hash,
  myitem,
  maxbidprice,
  incPrice,
  itemPrice,
  maxBidder,
  isExpired,
}) => {
  const { connect, active, account, activate, deactivate, library } =
    useWallet();

  const [visibleModalPurchase, setVisibleModalPurchase] = useState(false);
  const [visibleModalBid, setVisibleModalBid] = useState(false);
  const [visibleModalAccept, setVisibleModalAccept] = useState(false);
  const [visibleModalSale, setVisibleModalSale] = useState(false);

  const [marketcontract, setMarketContract] = useState(null);
  const [ftmcontract, setFtmContract] = useState(null);
  const history = useHistory();
  //sale
  const [saleprice, SetSalePrice] = useState(0);
  const [salestatus, SetSaleStatus] = useState(false);
  const [minprice, SetMinPrice] = useState(0.01);

  //const [itemPrice, SetItemPrice] = useState(0);
  // const [incPrice, SetIncPrice] = useState(0);

  //bid
  const [bidprice, SetBidPrice] = useState(0);

  //pending tx
  const [txstatus, SetTxStatus] = useState(false);

  const handleApprove = async () => {
    try {
      SetTxStatus(true);
      const response = await ftmcontract.approve(AuctionRegistrarAddress, hash);
      await response
        .wait((r) => {
          console.log(r);
        })
        .catch((e) => console.log(e));

      SetSaleStatus(true);
      SetTxStatus(false);
    } catch (e) {
      SetTxStatus(false);
      console.log(e);
      toast.error(e?.data?.message);
    }
  };

  const handleConfirm = async () => {
    try {
      if (saleprice <= 0) {
        alert("Price should be bigger than zero");
        return;
      }
      SetTxStatus(true);
      const response = await marketcontract.createOrder(
        FTMBaseRegistrarAddress,
        hash,
        ethers.utils.parseEther(saleprice.toString()),
        ethers.utils.parseEther(minprice.toString())
      );
      await response
        .wait((r) => {
          console.log(r);
        })
        .catch((e) => console.log(e));
      SetTxStatus(false);
      history.push("/");
    } catch (e) {
      SetTxStatus(false);
      console.log(e);
      toast.error(e?.data?.message);
    }
  };

  const handleAccept = async () => {
    try {
      await marketcontract.acceptBid(FTMBaseRegistrarAddress, hash);
    } catch (e) {
      console.log(e);
      toast.error(e?.data?.message);
    }
  };

  const handleCancel = async () => {
    try {
      await marketcontract.cancelOrder(FTMBaseRegistrarAddress, hash);
    } catch (e) {
      console.log(e);
      toast.error(e?.data?.message);
    }
  };

  const handleCancelBid = async () => {
    try {
      await marketcontract.cancelBid(FTMBaseRegistrarAddress, hash);
    } catch (e) {
      console.log(e);
      toast.error(e?.data?.message);
    }
  };

  const handlePurchase = async () => {
    try {
      let overrides = {
        value: ethers.utils.parseEther(itemPrice.toString()),
      };
      await marketcontract.safeExecuteOrder(
        FTMBaseRegistrarAddress,
        hash,
        ethers.utils.parseEther(itemPrice.toString()),
        overrides
      );
    } catch (e) {
      console.log(e);
      toast.error(e?.data?.message);
    }
  };

  const handleBid = async () => {
    if (!marketcontract) return;

    if (bidprice <= 0 || bidprice < incPrice + maxbidprice) {
      toast.error("You have to input right amount");
      return;
    }
    let overrides = {
      value: ethers.utils.parseEther(bidprice.toString()),
    };
    try {
      await marketcontract.safePlaceBid(
        FTMBaseRegistrarAddress,
        hash,
        overrides
      );
    } catch (e) {
      toast.error(e?.data?.message);
    }
  };

  useEffect(async () => {
    if (library) {
      let ftmregistrar = getContractFTM(library.getSigner(account));

      if (ftmregistrar) setFtmContract(ftmregistrar);

      let nftmarket = getContractHash(library.getSigner(account));

      if (nftmarket) setMarketContract(nftmarket);
    }
  }, [library]);

  if (!myitem) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className={cn(styles.control, className)}>
        {!owner && myitem.length === 0 ? (
          <div className={cn("status-stroke-red", styles.price)}>
            Not On Sale
          </div>
        ) : !owner ? (
          <>
            <div className={styles.btns}>
              {!isExpired && (
                <>
                  <span
                    className={styles.control_btns}
                    onClick={() => setVisibleModalPurchase(true)}
                  >
                    <AwesomeButton type="primary" size="medium">
                      Buy now!
                    </AwesomeButton>
                  </span>
                  <span
                    className={styles.control_btns}
                    onClick={() => setVisibleModalBid(true)}
                  >
                    <AwesomeButton type="primary" size="medium">
                      Make an offer
                    </AwesomeButton>
                  </span>
                </>
              )}
            </div>
            <span
              className={styles.control_btns}
              onClick={() => handleCancelBid()}
            >
              <AwesomeButton type="primary" size="medium">
                Cancel offer
              </AwesomeButton>
            </span>
          </>
        ) : (!myitem || myitem?.length === 0) && !isExpired ? (
          <button
            className={cn("button", styles.button)}
            onClick={() => setVisibleModalSale(true)}
          >
            Put on sale
          </button>
        ) : (
          <div className={styles.btns}>
            {maxBidder && !isExpired && (
              <button
                className={cn("button", styles.button)}
                onClick={() => setVisibleModalAccept(true)}
              >
                Accept
              </button>
            )}
            <button
              className={cn("button", styles.button)}
              onClick={() => handleCancel()}
            >
              Cancel Sale
            </button>
          </div>
        )}
      </div>
      {/*------- MODALS -----------*/}
      <Modal
        visible={visibleModalPurchase}
        onClose={() => setVisibleModalPurchase(false)}
      >
        <Checkout handlePurchase={handlePurchase} itemPrice={itemPrice} />
        {/* <SuccessfullyPurchased /> */}
      </Modal>
      <Modal
        visible={visibleModalBid}
        onClose={() => setVisibleModalBid(false)}
      >
        {!active && <Connect />}
        <Bid
          handleBid={handleBid}
          bidprice={bidprice}
          SetBidPrice={SetBidPrice}
          incPrice={incPrice}
          maxbidprice={maxbidprice}
        />
      </Modal>
      <Modal
        visible={visibleModalAccept}
        onClose={() => setVisibleModalAccept(false)}
      >
        <Accept
          handleAccept={handleAccept}
          maxbidprice={maxbidprice}
          maxBidder={maxBidder}
        />
      </Modal>
      <Modal
        visible={visibleModalSale}
        onClose={() => setVisibleModalSale(false)}
      >
        <PutSale
          salestatus={salestatus}
          handleApprove={handleApprove}
          handleConfirm={handleConfirm}
          saleprice={saleprice}
          SetSalePrice={SetSalePrice}
          minprice={minprice}
          SetMinPrice={SetMinPrice}
          txstatus={txstatus}
        />
      </Modal>
    </>
  );
};

export default Control;
