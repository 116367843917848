import cn from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import styles from "./Card.module.sass";

/**
 * @param {tokenId} string
 * @param {labelName} string
 * @param {image} string
 * @param {price} number 
 * @param {registrationDate} Date 
 */

const MarketCard = ({
  tokenId,
  className,
  labelName,
  image,
  price,
  registrationDate
}) => {
  return (
    <div className={cn(styles.card, className)}>
      <Link className={styles.link} to={"/item/" + tokenId}>
        <div className={styles.preview}>
          <img src={image} alt="Card" />
          <div className={styles.control}>
            {/* <div
              className={cn(
                { "status-green": item.category === "green" },
                styles.category
              )}
            >
              {item.categoryText}
            </div>
            <button
              className={cn(styles.favorite, { [styles.active]: visible })}
              onClick={() => setVisible(!visible)}
            >
              <Icon name="heart" size="20" />
            </button>
            <button className={cn("button-small", styles.button)}>
              <Link  to={"/item/"+item.id}>
              <span>Place a bid</span>
              <Icon name="scatter-up" size="16" />
              </Link>
            </button>*/}
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.line}>
            <div className={styles.title}>{labelName}.ace</div>
          </div>
          <div className={styles.line}>
            {/* <div className={styles.users}>
              {item.users.map((x, index) => (
                <div className={styles.avatar} key={index}>
                  <img src={x.avatar} alt="Avatar" />
                </div>
              ))}
            </div>
            <div className={styles.counter}>{item.counter}</div> */}
          </div>
        </div>
        <div className={styles.foot}>
          <div className={styles.status}>
            <Icon name="candlesticks-up" size="20" />
            {registrationDate && (
              <>
                <span>{new Intl.DateTimeFormat().format(registrationDate)}</span>
              </>
            )}
          </div>
          <div className={styles.bid}>
            Price : <span>{price} ACE</span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default MarketCard;
