import cn from "classnames";
import React, { useState } from "react";
import { AwesomeButton } from "react-awesome-button";
import AwesomeButtonStyles from "react-awesome-button/src/styles/styles.scss";
import Dropdown from "../../components/Dropdown";
import Icon from "../../components/Icon";
import MarketCard from "../../components/MarketCard";
import Transaction from "../../components/Transactions";
import { ApolloClient, InMemoryCache, useQuery, gql } from "@apollo/client";
import { useMetadataContext } from "../../context/MetadataContext";
import { useWallet } from "../../hooks/useWallet";
import styles from "./Search01.module.sass";

const navLinks = []; // ["All items", "Art", "Game", "Photography", "Music", "Video"];

const dateOptions = [
  "Newest",
  "Oldest",
  "Price (Highest to Lowest)",
  "Price (Lowest to Highest)",
  "Length (Shortest to Longest)",
  "Length (Longest to Shortest)",
];
const likesOptions = ["Most liked", "Least liked"];
const colorOptions = ["All colors", "Black", "Green", "Pink", "Purple"];
const creatorOptions = ["Verified only", "All", "Most liked"];
const categoryOptions = [
  "All",
  "Supreme",
  "Gold",
  "Silver",
  "Copper",
  "Carbon",
  "Obsidian",
];

const marketUri = process.env.REACT_APP_MARKETPLACE_SUBGRAPH;
const marketClient = new ApolloClient({
  uri: marketUri,
  cache: new InMemoryCache(),
});

const GET_LAST_TRANSACTIONS = gql`
  query Transaction($first: Int) {
    auctionedNames(
      where: { state: FINALIZED }
      first: $first
      orderBy: timestamp
      orderDirection: desc
    ) {
      id
      price
      tokenID
      seller
      state
      timestamp
    }
  }
`;

const Search = () => {
  const { aceDomains } = useMetadataContext();

  const [activeIndex, setActiveIndex] = useState(0);
  const [date, setDate] = useState(dateOptions[0]);
  const [category, setCategory] = useState(categoryOptions[0]);
  const [likes, setLikes] = useState(likesOptions[0]);
  const [color, setColor] = useState(colorOptions[0]);
  const [creator, setCreator] = useState(creatorOptions[0]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [values, setValues] = useState([5]);

  const STEP = 0.1;
  const MIN = 0.01;
  const MAX = 10;
  var cardDataList = [];
  var filterData = [];

  if (page) {
    switch (category) {
      case "All":
        filterData = aceDomains.filter((aceDomain) =>
          aceDomain.labelName.includes(search)
        );
        break;

      case "Supreme":
        filterData = aceDomains.filter(
          (aceDomain) =>
            aceDomain.supreme && aceDomain.labelName.includes(search)
        );
        // filterData = filterData
        //   .filter((aceDomain) => aceDomain.supreme)
        //   .slice(0, page * 21);
        break;

      case "Carbon":
        filterData = aceDomains.filter(
          (aceDomain) =>
            aceDomain.category == "Carbon" &&
            aceDomain.labelName.includes(search)
        );
        break;

      case "Gold":
        filterData = aceDomains.filter(
          (aceDomain) =>
            aceDomain.category == "Gold" && aceDomain.labelName.includes(search)
        );
        break;

      case "Silver":
        filterData = aceDomains.filter(
          (aceDomain) =>
            aceDomain.category == "Silver" &&
            aceDomain.labelName.includes(search)
        );
        break;

      case "Copper":
        filterData = aceDomains.filter(
          (aceDomain) =>
            aceDomain.category == "Copper" &&
            aceDomain.labelName.includes(search)
        );
        break;

      case "Obsidian":
        filterData = aceDomains.filter(
          (aceDomain) =>
            aceDomain.category == "Obsidian" &&
            aceDomain.labelName.includes(search)
        );
        break;

      default:
        break;
    }
  }

  switch (date) {
    case "Newest":
      cardDataList = filterData
        .sort((a, b) => b.registrationDate - a.registrationDate)
        .slice(0, page * 21);
      break;

    case "Oldest":
      cardDataList = filterData
        .sort((a, b) => a.registrationDate - b.registrationDate)
        .slice(0, page * 21);
      break;

    case "Price (Highest to Lowest)":
      cardDataList = filterData
        .sort((a, b) => b.price - a.price)
        .slice(0, page * 21);
      break;

    case "Price (Lowest to Highest)":
      cardDataList = filterData
        .sort((a, b) => a.price - b.price)
        .slice(0, page * 21);
      break;

    case "Length (Shortest to Longest)":
      cardDataList = filterData
        .sort((a, b) => a.nameLength - b.nameLength)
        .slice(0, page * 21);
      break;

    case "Length (Longest to Shortest)":
      cardDataList = filterData
        .sort((a, b) => b.nameLength - a.nameLength)
        .slice(0, page * 21);
      break;

    default:
      break;
  }

  const { data: transactions } = useQuery(GET_LAST_TRANSACTIONS, {
    variables: { first: 10 },
    client: marketClient,
  });

  return (
    <div className={cn("section-pt80", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div
          className={styles.top}
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
          }}
        >
          <form
            className={styles.search}
            action=""
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <input
              className={styles.input}
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              name="search"
              placeholder="Search ..."
              required
            />
            <button className={styles.result} style={{ background: "none" }}>
              <Icon name="search" size="16" />
            </button>
          </form>
          <div className={styles.dropdown}>
            <Dropdown
              className={styles.dropdown}
              value={date}
              setValue={setDate}
              options={dateOptions}
            />
          </div>
          <div className={styles.categoryDropdown}>
            <Dropdown
              className={styles.categoryDropdown}
              value={category}
              setValue={setCategory}
              options={categoryOptions}
            />
          </div>
        </div>
        <div className={styles.sorting}>
          <div className={styles.nav}>
            {navLinks.map((x, index) => (
              <button
                className={cn(styles.link, {
                  [styles.active]: index === activeIndex,
                })}
                onClick={() => setActiveIndex(index)}
                key={index}
              >
                {x}
              </button>
            ))}
          </div>
        </div>

        {/* Card Gallery */}
        <div className={styles.row}>
          <div className={styles.wrapper}>
            <div className={styles.list}>
              {cardDataList.map((cardData, index) => (
                <MarketCard
                  className={styles.card}
                  key={index}
                  tokenId={cardData.tokenId}
                  labelName={cardData.labelName}
                  image={cardData.image}
                  price={cardData.price}
                  registrationDate={cardData.registrationDate}
                />
              ))}
            </div>
            <div className={styles.btns}>
              <span onClick={() => setPage(page + 1)}>
                <AwesomeButton type="primary" size="medium">
                  Load more
                </AwesomeButton>
              </span>
            </div>
          </div>
        </div>
        <div className={styles.table}>
          <h1>Recent Transactions</h1>
          <hr></hr>
          <table>
            <thead>
              <tr>
                <th>Domain</th>
                <th>Name</th>
                <th>Price</th>
                <th>Seller</th>
                {/* <th>TransactionID</th> */}
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              {transactions &&
                transactions.auctionedNames.map((transaction, index) => (
                  <Transaction item={transaction} key={index} />
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Search;
