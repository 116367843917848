import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Profile.module.sass";
import Icon from "../../components/Icon";
import User from "./User";
import Items from "./Items";
import Followers from "./Followers";
import { useWallet } from '../../hooks/useWallet';
import * as dotenv from 'dotenv'
// data
import { bids } from "../../mocks/bids";
import { isStepDivisible } from "react-range/lib/utils";
import { ApolloClient, InMemoryCache, useQuery, gql } from '@apollo/client'
import { ethers } from "ethers";
dotenv.config()

const ETH_NAMEHASH = '0xc3a2b194615fe15824a8c25f10cb10180b5f20e68643314a4bbbf71a88c1ac2a';

let ensUri = process.env.REACT_APP_DOMAIN_SUBGRAPH
const ensClient = new ApolloClient({ uri: ensUri,cache: new InMemoryCache() });

// export const GET_DOMAINS = gql`
//   query getDomains($tokenId: String) {
//     domain(id: $tokenId) {
//       id
//       labelName
//       labelhash
//       name
//       owner {
//         id
//       }
//       parent {
//         id
//       }
//       resolver {
//         texts
//       }
//     }
//   }
// `;

export const GET_DOMAINS_ON_SALE = gql`
    query getSales($seller: String) {
        auctionedNames(where: { seller: $seller, state: "AUCTION"  }) {
            id
            tokenID
            price
            seller
        }
    }
`;

export const GET_DOMAINS = gql`
  query getDomains($id: ID!, $expiryDate: Int) {
    account(id: $id) {
      registrations(
        where: { expiryDate_gt: $expiryDate }
      ) {
        expiryDate
        domain {
          id
          labelName
          labelhash
          name
          isMigrated
          parent {
            name
          }
        }
      }
    }
  }
`;

export const GET_DOMAINS_BY_LABELHASH = gql`
  query getDomains($tokenId: String) {
    domains(
      where: {
        parent: "${ETH_NAMEHASH}",
        labelhash: $tokenId
      }
    ) {
      id
      labelName
      labelhash
      name
      owner {
        id
      }
      parent {
        id
      }
      resolver {
        texts
      }
    }
  }
`;

export const GET_REGISTRATIONS = gql`
  query getRegistration($labelhash: String) {
    registrations(
      orderBy: registrationDate
      orderDirection: desc
      where: { id: $labelhash }
    ) {
      labelName
      registrationDate
      expiryDate
    }
  }
`;

const navLinks = [
  "Owned",
  "On Sale",
  // "Collectibles",
  // "Likes",
  // "Following",
  // "Followers",
];

const socials = [
  {
    title: "twitter",
    url: "https://twitter.com/ui8",
  },
  {
    title: "instagram",
    url: "https://www.instagram.com/ui8net/",
  },
  {
    title: "facebook",
    url: "https://www.facebook.com/ui8.net/",
  },
];

const Profile = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [visible, setVisible] = useState(false);
  const { connect, active, account, activate, deactivate } = useWallet();
  const currentEpoch = Math.round(Date.now() / 1000) ;
  //console.log('account',ethers.utils.getAddress(account))
  const { data:domainData } = useQuery(GET_DOMAINS, {variables: { id:account?.toLowerCase(), expiryDate: currentEpoch }, client:ensClient});
  const { data:auctionedNames } = useQuery(GET_DOMAINS_ON_SALE, {variables: { seller:account?.toLowerCase()}});

  useEffect(()=>{


  },[account])
  return (
    <div className={styles.profile}>

      <div className={styles.body}>
        <div className={cn("container", styles.container)}>
          {/* <User className={styles.user} item={socials} /> */}
          <div className={styles.wrapper}>
            <div className={styles.nav}>
              {navLinks.map((x, index) => (
                <button
                  className={cn(styles.link, {
                    [styles.active]: index === activeIndex,
                  })}
                  key={index}
                  onClick={() => setActiveIndex(index)}
                >
                  {x}
                </button>
              ))}
            </div>
            <div className={styles.group}>
              <div className={styles.item}>
                {activeIndex === 0 && domainData &&(
                  <Items class={styles.items} items={domainData.account?.registrations} />
                )}
                {activeIndex === 1 && (
                  <Items class={styles.items} items={auctionedNames?.auctionedNames} isAuctioned={true} />
                )}
                {/*{activeIndex === 2 && (*/}
                {/*  <Items class={styles.items} items={bids.slice(0, 2)} />*/}
                {/*)}*/}
                {/*{activeIndex === 3 && (*/}
                {/*  <Items class={styles.items} items={bids.slice(0, 3)} />*/}
                {/*)}*/}
                {/*{activeIndex === 4 && (*/}
                {/*  <Followers className={styles.followers} items={following} />*/}
                {/*)}*/}
                {/*{activeIndex === 5 && (*/}
                {/*  <Followers className={styles.followers} items={followers} />*/}
                {/*)}*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
