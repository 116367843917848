import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Card.module.sass";
import Icon from "../Icon";
import { ApolloClient, InMemoryCache, useQuery, gql } from "@apollo/client";
import * as dotenv from "dotenv";
import { ethers } from "ethers";
import Loader from "../Loader";
dotenv.config();

let ensUri = process.env.REACT_APP_DOMAIN_SUBGRAPH;
const ensClient = new ApolloClient({ uri: ensUri, cache: new InMemoryCache() });

const GET_DOMAIN_INFO = gql`
  query getDomains($tokenId: String) {
    domains(
      where: {
        id: $tokenId
        parent: "0xc3a2b194615fe15824a8c25f10cb10180b5f20e68643314a4bbbf71a88c1ac2a"
      }
    ) {
      id
      labelName
      labelhash
      name
      owner {
        id
      }
      parent {
        id
      }
    }
  }
`;

const GET_ITEM_BIDS = gql`
    query getBids ($tokenId: String) {
        bids(where: {tokenID: $tokenId, state: "OFFER"}){
            id
            tokenID
            price
            state
            price
        }
    }
`;
//
// const GET_LABEL_NAME = gql`
//   query Domain($labelhash: String!){
//     domains(where: {labelhash:$labelhash}) {
//       id
//       name
//       labelName
//       labelhash
//     }
//   }
// `;

const Card = ({ className, item, isAuctioned = false }) => {
  const [visible, setVisible] = useState(false);


  const domainId = isAuctioned? item.tokenID : item.domain.id;

  const { data:domain } = useQuery(GET_DOMAIN_INFO, {variables: { tokenId:domainId }, client:ensClient});
  const { data:bids } = useQuery(GET_ITEM_BIDS, {variables: { tokenId:domainId } });

  if (!domain || !bids) {
    return (
      <div className={cn(styles.card, className)}>
        <div className={styles.preview}>
          <Loader className={styles.loader} />
        </div>
      </div>
    )
  }
  
  const cardDomain = domain.domains[0];
  const domainName = cardDomain.labelName;
  const labelHash = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(domainName));
  const tokenId = ethers.BigNumber.from(labelHash).toString();
  const baseRegistrar = process.env.REACT_APP_DOMAIN_CONTRACT;

  const convertPrice = (labelprice) =>{
    let price = parseInt(labelprice);
    return (price/10**18);
  }
  console.log(bids);
  // const { data:labelData } = useQuery(GET_LABEL_NAME, {variables: { labelhash:item.id }, client:ensClient});
  // console.log('labelData',labelData)
  return (
    <div className={cn(styles.card, className)}>
      <div className={styles.preview}>
        <img
          src={`${process.env.REACT_APP_METADATA_SERVICE}/endurance/${baseRegistrar}/${tokenId}/image`}
          alt="Card"
        />
      </div>
      <Link className={styles.link} to={"/item/" + cardDomain.id}>
        <div className={styles.body}>
          <div className={styles.line}>
            <div className={styles.title}>{cardDomain.name}</div>
            {/* <div className={styles.price}>{item.price}</div> */}
          </div>
          <div className={styles.line}>
            {/* <div className={styles.users}>
              {item.users.map((x, index) => (
                <div className={styles.avatar} key={index}>
                  <img src={x.avatar} alt="Avatar" />
                </div>
              ))}
            </div>
            <div className={styles.counter}>{item.counter}</div> */}
          </div>
        </div>
        <div className={styles.foot}>
          <div className={styles.status}>
            <Icon name="candlesticks-up" size="20" />
            {item.expiryDate && (
              <>
                Registration Date{" "}
                <span>
                  {new Intl.DateTimeFormat().format(
                    new Date(parseInt(item.expiryDate, 10) * 1000)
                  )}
                </span>
              </>
            )}
          </div>
          <div className={styles.bid}>
              {bids.bids.length > 0 ? <> Highest Bid: <span style={{color: "#FFFFFF"}}>{convertPrice(bids.bids[0].price)} ACE</span> </> : <>No Bids</>}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Card;
