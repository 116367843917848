import React, { useRef, useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import { ApolloClient, InMemoryCache, useQuery, gql } from "@apollo/client";
import * as dotenv from "dotenv";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import axios from "axios";
// import {fusionistProvider} from "../../utils/connector";
// import  { Redirect } from 'react-router-dom'
dotenv.config();

let ensUri = process.env.REACT_APP_DOMAIN_SUBGRAPH;
const ensClient = new ApolloClient({ uri: ensUri, cache: new InMemoryCache() });
const baseRegistrar = process.env.REACT_APP_DOMAIN_CONTRACT;

const GET_NAME = gql`
  query getName($domainId: String!) {
    domain(id: $domainId) {
      id
      labelName
      labelhash
    }
  }
`;

const Transaction = ({ item, index }) => {
  console.log(item);
  let labelHash, tokenId;
  const [visible, setVisible] = useState(false);
  const [sellerResolution, setSellerResolution] = useState(null);
  const copyTransactionRef = useRef(null);
  const copyOwnerRef = useRef(null);

  const { data: domainData } = useQuery(GET_NAME, {
    variables: { domainId: item.tokenID },
    client: ensClient,
  });
  const name = domainData?.domain?.labelName;
  if (name) {
    labelHash = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(name));
    tokenId = ethers.BigNumber.from(labelHash).toString();
  }

  const converPrice = (labelprice) => {
    let price = parseInt(labelprice);
    return price / 10 ** 18;
  };

  const handleOwnerCopy = () => {
    const textToCopy = copyOwnerRef.current.dataset.copyText;
    navigator.clipboard.writeText(textToCopy);
    toast.success("Copied to clipboard!", { autoClose: 1000 });
  };

  const handleTransactionCopy = () => {
    const textToCopy = copyTransactionRef.current.dataset.copyText;
    navigator.clipboard.writeText(textToCopy);
    toast.success("Copied to clipboard!", { autoClose: 1000 });
  };

  // if(item.seller){
  //   fusionistProvider.lookupAddress(item.seller).then((response) => {
  //     setSellerResolution(response)
  //   }).catch((e)=>{
  //     console.log("Error fetching resolution")
  //   })
  // }

  return (
    <tr key={index}>
      <td style={{ maxHeight: "100px" }}>
        {domainData ? (
          <img
            src={`${
              process.env.REACT_APP_METADATA_SERVICE
            }/endurance/${baseRegistrar}/${ethers.BigNumber.from(
              domainData.domain.labelhash
            ).toString()}/image`}
            alt="Domain Not Found"
          />
        ) : (
          ""
        )}
      </td>
      <td>
        <div
          style={{
            minHeight: "100px",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          {domainData ? domainData.domain.labelName.toUpperCase() : ""}.ACE
        </div>
      </td>
      <td>
        <div
          style={{
            minHeight: "100px",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          {converPrice(item.price)} ACE
        </div>
      </td>
      <td>
        <div
          style={{
            minHeight: "100px",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <span
            ref={copyOwnerRef}
            data-copy-text={item.seller}
            onClick={handleOwnerCopy}
          >
            {sellerResolution
              ? sellerResolution
              : `${item.seller.slice(0, 5)}...${item.seller.slice(
                  item.seller.length - 5
                )}`}
          </span>
        </div>
      </td>
      <td>
        <div
          style={{
            minHeight: "100px",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          {item.state == "FINALIZED" ? "SOLD" : item.state}
        </div>
      </td>
    </tr>
  );
};

export default Transaction;
