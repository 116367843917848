import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import cn from "classnames";
import styles from "./Item.module.sass";
import Users from "./Users";
import Control from "./Control";
import { useWallet } from "../../hooks/useWallet";
import { ApolloClient, InMemoryCache, useQuery, gql } from "@apollo/client";
import * as dotenv from "dotenv";
import { ethers } from "ethers";
import { REGISTRAR_CONTRACT_ADDRESS } from "../../constants/base.constant";
import { fusionistProvider } from "../../utils/connector";
import { BiTransfer } from "react-icons/bi";
import { TfiHummer } from "react-icons/tfi";
dotenv.config();

let ensUri = process.env.REACT_APP_DOMAIN_SUBGRAPH;
let marketPlaceUri = process.env.REACT_APP_MARKETPLACE_SUBGRAPH;
const ensClient = new ApolloClient({ uri: ensUri, cache: new InMemoryCache() });

export const GET_DOMAINS = gql`
  query getDomains($tokenId: String) {
    domain(id: $tokenId) {
      id
      labelName
      labelhash
      name
      owner {
        id
        registrations {
          registrationDate
          cost
        }
      }
      parent {
        id
      }
      events {
        ... on Transfer {
          id
          transactionID
          owner {
            id
            registrations {
              registrationDate
            }
          }
        }
        ... on NewOwner {
          id
          owner {
            id
          }
        }
      }
    }
  }
`;

export const GET_HashItem = gql`
  query getHashItem($tokenId: String) {
    nfts(first: 1, where: { tokenID: $tokenId }) {
      id
      tokenID
      owner
      expiryDate
    }
  }
`;

export const GET_SaleItems = gql`
  query getSales($tokenId: BigInt) {
    auctionedNames(where: { assertId: $tokenId, state: "AUCTION" }) {
      id
      price
      minBidPrice
      seller
      tokenID
    }
  }
`;

const navLinks = []; //["Bids log", "Info", "History", "Bids"];

const Item = () => {
  let labelHash,
    tokenId,
    registrationDate,
    noDate = "5 days ago";
  const { itemId } = useParams();
  const history = useHistory();
  const [activeIndex, setActiveIndex] = useState(0);
  const { connect, active, account, activate, deactivate } = useWallet();

  const baseRegistrar = process.env.REACT_APP_DOMAIN_CONTRACT;
  const { data: domain } = useQuery(GET_DOMAINS, {
    variables: { tokenId: itemId },
    client: ensClient,
  });

  console.log("Domain", domain);

  if (domain && domain.domain.owner.registrations[0]) {
    let date = domain.domain.owner.registrations[0].registrationDate;
    let today = new Date();
    let difference_mins = (today.getTime() - date * 1000) / 60000;
    let difference_hours = (today.getTime() - date * 1000) / 3600000;
    let difference_days = (today.getTime() - date * 1000) / (3600000 * 24);

    if (difference_mins < 60) {
      registrationDate = Math.round(difference_mins) + "m ago";
    } else if (difference_hours < 24) {
      registrationDate = Math.round(difference_hours) + "h ago";
    } else {
      registrationDate = Math.round(difference_days) + " days ago";
    }
  }

  const domainName = domain?.domain?.labelName;

  if (domainName) {
    labelHash = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(domainName));
    tokenId = ethers.BigNumber.from(labelHash).toString();
  }

  const { data: hashData } = useQuery(GET_HashItem, {
    variables: { tokenId: itemId },
  });

  const { data: saleData } = useQuery(GET_SaleItems, {
    variables: { tokenId: hashData?.nfts[0]?.id || 0 },
  });

  const [itemPrice, SetItemPrice] = useState(0);
  const [incPrice, SetIncPrice] = useState(0.1);
  const [maxbidprice, SetMaxBidPrice] = useState(0.1);
  const [maxBidder, setMaxBidder] = useState("");
  const [seller, SetSeller] = useState("");
  const [expiryDate, SetExpiryDate] = useState("");
  const [expiryDays, SetExpiryDays] = useState(0);
  const [resolution, setResolution] = useState(null);
  const [resolvedToAddres, setResolvedToAddress] = useState(null);
  const [resolvedFromAddress, setResolvedFromAddress] = useState(null);

  const isOwner = domain?.domain?.owner?.id === account?.toLowerCase();
  const fromAddress = domain?.domain?.events[0]?.owner?.id;
  const isRegistrarContract =
    fromAddress?.toLowerCase() === REGISTRAR_CONTRACT_ADDRESS;
  let toAddress = domain?.domain?.events[1]?.id
    ? domain?.domain?.events[1]?.owner?.id
    : domain?.domain?.events[2]?.owner?.id;

  const converPrice = (labelprice) => {
    let price = parseInt(labelprice);
    return price / 10 ** 18;
  };

  useEffect(() => {
    if (saleData && saleData.auctionedNames.length) {
      SetItemPrice(converPrice(saleData.auctionedNames[0].price));
      SetIncPrice(converPrice(saleData.auctionedNames[0].minBidPrice));
      SetSeller(saleData.auctionedNames[0].seller);
    }
    // if (hashData && hashData.nfts.length) {
    //   let stringDate = new Intl.DateTimeFormat().format(
    //     new Date(parseInt(hashData.nfts[0].expiryDate, 10) * 1000)
    //   )
    //   const date = stringDate.split('/')
    //   const newDate = date[0] + '/' + date[1] + '/' + date[2];
    //
    //   const date2 = new Date(newDate).toString();
    //   const date3 = new Date(newDate);
    //   const nowDate = new Date();
    //   const difference_days = (date3.getTime() - nowDate.getTime()) / (1000 * 3600 * 24);
    //   SetExpiryDays(Math.floor(difference_days))
    //   SetExpiryDate(date2.slice(0, 16));
    // }
  }, [saleData]);

  useEffect(() => {
    (async () => {
      const metadataQuery = await fetch(
        `${process.env.REACT_APP_METADATA_SERVICE}/endurance/${baseRegistrar}/${tokenId}`
      );
      const metadata = await metadataQuery.json();

      console.log("metadata", metadata);
      if (metadata.message === "No results found.") {
        console.log("No results found from the metadata service");
        SetExpiryDays(-1);
        return;
      }
      SetExpiryDate(
        metadata?.attributes?.find((x) => x.trait_type === "Expiration Date")
          ?.value
      );
      const date1 = new Date(
        metadata?.attributes?.find(
          (x) => x.trait_type === "Expiration Date"
        )?.value
      );
      const date2 = new Date();
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      SetExpiryDays(diffDays);

      console.log("expiryDate", expiryDate);
    })();
  }, [baseRegistrar, tokenId]);

  if (saleData?.auctionedNames[0]?.seller !== undefined) {
    fusionistProvider
      .lookupAddress(saleData?.auctionedNames[0]?.seller)
      .then((response) => {
        setResolution(response);
      });
  }

  // if(toAddress){
  //   fusionistProvider.lookupAddress(toAddress).then((response) => {
  //     setResolvedToAddress(response)
  //   })
  // }
  //
  // if(fromAddress && !isRegistrarContract){
  //   fusionistProvider.lookupAddress(fromAddress).then((response) => {
  //     console.log(response)
  //     setResolvedFromAddress(response)
  //   })
  // }

  return (
    <>
      <div className={cn("section", styles.section)}>
        <div className={cn("container", styles.container)}>
          <div className={styles.bg}>
            <div className={styles.preview}>
              <img
                src={`${process.env.REACT_APP_METADATA_SERVICE}/endurance/${baseRegistrar}/${tokenId}/image`}
                alt="Item"
              />
            </div>
            {/* <Options className={styles.options} /> */}
          </div>
          <div className={styles.details}>
            <h1 className={cn("h3", styles.title)}>{domain?.domain.name}</h1>
            <div className={styles.cost}>
              {saleData?.auctionedNames.length !== 0 && (
                <div className={cn("status-stroke-green", styles.price)}>
                  {isOwner ? "Selling for" : "Buy for"} {Math.round(itemPrice)}{" "}
                  ACE
                </div>
              )}
            </div>
            <div>
              <h3>Minimum Bid Price</h3>
              <h4>{incPrice} ACE</h4>
            </div>
            <div className={styles.seller}>
              <h3>Owner</h3>
              <h4>
                {resolution ? resolution : saleData?.auctionedNames[0]?.seller}
              </h4>
            </div>
            <div className={styles.expiryDate}>
              <h3>Domain Expiration</h3>
              {expiryDays < 0 && <h4 style={{ color: "red" }}>Expired</h4>}
              {expiryDays === 0 && <h4 style={{ color: "red" }}>Today</h4>}
              {expiryDays > 0 && expiryDays < 30 && (
                <h4 style={{ color: "orange" }}>
                  In {expiryDays} days,{" "}
                  {`${new Date(Number(expiryDate)).toLocaleDateString()}`}
                </h4>
              )}
              {expiryDays > 0 && (
                <h4>
                  In {expiryDays} days,{" "}
                  {`${new Date(Number(expiryDate)).toLocaleDateString()}`}
                </h4>
              )}
            </div>
            <div>
              <div className={styles.nav}>
                {navLinks.map((x, index) => (
                  <button
                    className={cn(
                      { [styles.active]: index === activeIndex },
                      styles.link
                    )}
                    onClick={() => setActiveIndex(index)}
                    key={index}
                  >
                    {x}
                  </button>
                ))}
              </div>
              <Users
                className={styles.users}
                itemId={itemId}
                activeIndex={activeIndex}
                SetMaxBidPrice={SetMaxBidPrice}
                setMaxBidder={setMaxBidder}
              />
            </div>
            <Control
              className={styles.control}
              owner={isOwner}
              hash={hashData?.nfts[0]?.id}
              myitem={saleData?.auctionedNames}
              maxbidprice={maxbidprice}
              incPrice={incPrice}
              itemPrice={itemPrice}
              maxBidder={maxBidder}
              isExpired={expiryDays < 0}
            />
          </div>
        </div>
        <div className={styles.table}>
          <h1>Domain Activities</h1>
          <hr></hr>
          <table>
            <thead>
              <tr>
                <th>Event</th>
                <th>Price</th>
                <th>From</th>
                <th>To</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {isRegistrarContract ? (
                  <td>
                    <TfiHummer size="16" fill={"white"} /> Mint{" "}
                  </td>
                ) : (
                  <td>
                    <BiTransfer size="16" fill={"white"} /> Transfer{" "}
                  </td>
                )}

                {/* Price */}
                <td>
                  {saleData && saleData.auctionedNames[0]
                    ? `${Math.round(
                        +saleData.auctionedNames[0].price / 10 ** 18
                      )} ACE`
                    : 0}
                </td>

                {/* From */}
                <td>
                  {isRegistrarContract
                    ? "RegistrarContract"
                    : resolvedFromAddress
                    ? resolvedFromAddress
                    : fromAddress}{" "}
                  <a
                    href={
                      "https://explorer-endurance.fusionist.io/address/" +
                      fromAddress
                    }
                    target="_blank"
                  >
                    <img
                      style={{ width: "25px", margin: 0, cursor: "pointer" }}
                      src="../endurance-scan.png"
                    />
                  </a>
                </td>

                {/* To */}
                <td>
                  {resolvedToAddres ? resolvedToAddres : toAddress}{" "}
                  <a
                    href={
                      "https://explorer-endurance.fusionist.io/address/" +
                      toAddress
                    }
                    target="_blank"
                  >
                    <img
                      style={{ width: "25px", margin: 0, cursor: "pointer" }}
                      src="../endurance-scan.png"
                    />
                  </a>
                </td>

                {/* RegistrationDate */}
                <td>{registrationDate ? registrationDate : noDate}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Item;
